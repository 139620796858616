import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import {Table,Alert,Container,Row,Col,Button,Modal,Form} from 'react-bootstrap';
import axios from 'axios';
import { config } from '../Constants'
import querySearch from "stringquery";
import './publicdataview.scss'

var baseUrl = config.url.API_URL
var opsTimerID;
class PublicDataView extends React.Component {
    constructor() {
        super();
        this.state = { pubdata: [] };
      }
    
    async componentDidMount() {
    
        this.loadPublicData()
        if(opsTimerID) {
            clearInterval(opsTimerID);
        }
        var self = this;
        opsTimerID = setInterval(function() {
         self.loadPublicData();
        }, 30 * 1000);
          
      
    }

    async loadPublicData() {
          
          if(this.props.defaultAirport) {
            const resp = await axios.get(baseUrl + "/api/info/publicdata?icao="+this.props.defaultAirport)
            const data = resp.data;
           
            if(resp.data) {
              this.setState(
                {
                  pubdata: data.publicFlightOperations,
                }
              )
            } 
          }
    }


    parseDate(timeString) {
        var millis = Date.parse(timeString);
        var parsedDate = new Date(millis);
        var minutes = parsedDate.getMinutes();
        if(minutes < 10) {
          minutes = "0"+minutes;
        }
        var hours = parsedDate.getHours();
        if(hours < 10) {
          hours = "0"+hours;
        }
        return "" + hours + "" + minutes;
      }

      parseDateNiceFormat(timeString) {
        var millis = Date.parse(timeString);
        var parsedDate = new Date(millis);
        var minutes = parsedDate.getMinutes();
        if(minutes < 10) {
          minutes = "0"+minutes;
        }
        var hours = parsedDate.getHours();
        if(hours < 10) {
          hours = "0"+hours;
        }
        return "" + hours + ":" + minutes;
      }
  

      renderString(text,maxLength) {
        text = text.toUpperCase();
        text = text.replace('-','');
        var currentLength = 0;
        var renderedText = text.split('').map((item, index) => {
            var cssClassName = "letter letter-"+item
            currentLength++;
            return(
               [<span className={cssClassName}></span>]
            )
        });
        var renderedArray = [renderedText];
        
        for(var i = currentLength; i<=maxLength; i++) {
            renderedArray.push((<span className="letter letter-blank"></span>));
        }
        return renderedArray;
          
      }
    
    renderRow(flightOperation) {
        return (
            <Row>
                <Col>
                    <div className="departure-board">
                        {this.renderString(flightOperation.opType,3)}
                        {this.renderString(this.parseDate(flightOperation.opTime),5)}
                        {this.renderString(flightOperation.aircraftReg,7)}
                    </div>
                </Col>
            </Row>
        )
    }

     

  render() {

    var flightOps = ""
    var titleRow = "Operations";
    var currentTime = this.parseDateNiceFormat(new Date())
    var airportName = "";
    var totalOps = 0;

    if(this.props.defaultAirport === "EFHF") {
        airportName = "Malmi Airport";
    } else if(this.props.defaultAirport === "EFNU") {
        airportName = "Nummela Airfield"
    }

    if(this.state && this.state.pubdata) {
        flightOps = this.state.pubdata.map((item,index) => {
            return this.renderRow(item);
        })

        
        totalOps = this.state.pubdata.length;
        
        
        
    }

    titleRow = currentTime + " - " + airportName + " - " + totalOps + " Operations today";

    
    

    
    

   return (
       <div id="publicdata">

       
       <Container>
           <Row>
               <Col>
                <h1 class="publicdatah1"><i className="fas fa-plane-departure"></i>{titleRow}</h1>
               </Col>
           </Row>
           <Row>
            <Col>
            {flightOps}
            </Col>

           </Row>
           
       </Container>
       </div>
  
   )
  }
     
}

export default PublicDataView;
