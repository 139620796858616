import React from 'react';
import {Container,Alert,Row,Col} from 'react-bootstrap';
import axios from 'axios';
import { config } from '../Constants'

class TrafficCircuitStatus extends React.Component {
    constructor() {
        super();
    }

    async componentDidMount(prevprops,prevstate) {
        var baseUrl = config.url.API_URL
        let data = this.props.approvalResponse;
        if(data && data.tc && data.approvalResponse) {
            let tc = data.tc;
            if(tc.icao && tc.icao.length > 0) {
                const response = await axios.get(baseUrl + "/api/airport/publicinfo?icao="+tc.icao);
                this.setState({icaoInfo: response.data})
            }
            
        }
    }
    

    mapOperationType(operationType) {
        return "TC request"
    }

    mapOutcomeGeneric(outcome) {
            switch(outcome) {
                case 0:
                    return "Your TC request has been approved";
                case 1:
                    return "Action required! Contact duty officer for approval."
                case 2:
                    return "TC request rejected";
                case 3:
                    return "An error occured, please try again later";
            }
        return ""
    }

    mapOutcome(outcome, outcomeReason, operationType, contactPhone) {
        let operationTypeStr =  this.mapOperationType(operationType);
            switch(outcome) {
                case 0:
                    return "Your " + operationTypeStr + " has been Approved";
                case 1:
                    return "Your " + operationTypeStr + " is awaiting approval, contact: " + contactPhone;
                case 2:
                    return "Your " + operationTypeStr + " was rejected due to: " + outcomeReason;
                case 3:
                    return "An error occured, please try again later";
            }
        return ""
    }

    mapOutcomeToAlertVariant(outcome) {
        if(outcome != null) {
            switch(outcome) {
                case 0:
                    return "success";
                case 1:
                    return "warning";
                case 2:
                    return "danger";
                case 3:
                    return "danger";
            }
        }
        return ""
    }

    renderGenericResponse(outcome, outcomeReason, pic,email, start, end,icao,contactPhone,freeText) {
        let variant = this.mapOutcomeToAlertVariant(outcome);
        var snippet = (
            <Alert variant={variant}>
                {
                
                }
                
                <h1>
                    {this.mapOutcomeGeneric(outcome)}
                </h1>
                <h2>
                    {outcomeReason}
                </h2>
                <table>
                    <tbody>
                    <tr>
                        <td>PIC:</td>
                        <td>{pic}</td>
                    </tr>
                    <tr>
                        <td>email:</td>
                        <td>{email}</td>
                    </tr>
                    <tr>
                        <td>Icao:</td>
                        <td>{icao}</td>
                    </tr>
                    <tr>
                        <td>Start:</td>
                        <td>{start}</td>
                    </tr>
                    <tr>
                        <td>End:</td>
                        <td>{end}</td>
                    </tr>
                    <tr>
                        <td>AD Contact:</td>
                        <td>{contactPhone}</td>
                    </tr>
                    <tr>
                        <td>AD Info:</td>
                        <td>{freeText}</td>
                    </tr>
                    
                    </tbody>
                   
                </table>

            </Alert>

        );
        return snippet;
    }

    

    render() {

        let data = this.props.approvalResponse;
        let status = this.props.approvalHttpStatus;
        console.log("data: " + JSON.stringify(data));
        console.log("status: " + status);

        let contactPhone = "";
        let freeText = "";

        if(this.state && this.state.icaoInfo) {
            contactPhone = this.state.icaoInfo.contactPhone;
            freeText = this.state.icaoInfo.freeText;
        }


        const genericError = (
            <Container>
            <Col xs={12} fluid>
                <Container>
                    <Alert variant="danger">
                        Something went wrong, please try again later
                    </Alert>
                </Container>
            </Col>
        </Container>

            );

        if (status == 200) {
            if(data && data.approvalResponse && data.tc) {
                let approvalResponse = data.approvalResponse;
                let tc = data.tc;
                let outcome = approvalResponse.outcome;
                let outcomeReason = approvalResponse.outcomeReason;
                let pic = tc.pic;
                let email = tc.email;
                let dep = tc.start;
                let icao = tc.icao;
                let arr = tc.end;

               
                let depOutcome = approvalResponse.depOutcome;
                let arrOutcome = approvalResponse.ArrOutcome;
            
                console.log(JSON.stringify(approvalResponse));


                var genericRender = this.renderGenericResponse(outcome,outcomeReason,pic,email,dep,arr,icao,contactPhone,freeText);

                
                console.log("GOT HERE");

            
                return (
                    <Container>
                        <Row>
                        <Col xs={12}>
                            {genericRender}
                            </Col>
                        </Row>
                        
                    </Container>
                )


            } else {
                return genericError;
            }
            

        } else {
            return genericError;
        }

       
    }

}
export default TrafficCircuitStatus;