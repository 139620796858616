import React from 'react';
import {Container,Alert,Row,Col} from 'react-bootstrap';
import axios from 'axios';
import { config } from '../Constants'
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

var baseUrl = config.url.API_URL

const PermissionStatus = props => {
    const navigate = useNavigate();
    const location = useLocation()
  
    return <PermissionStatusOld navigate={navigate} location={location} {...props} />
  }

class PermissionStatusOld extends React.Component {
    constructor() {
        super();
    }

    async componentDidMount(prevprops,prevstate) {
        var baseUrl = config.url.API_URL
        let data = this.props.location.state.data;
        if(data && data.ppr && data.approvalResponse) {
            let ppr = data.ppr;
            if(ppr.depIcao && ppr.depIcao.length > 0) {
                const responseDep = await axios.get(baseUrl + "/api/airport/publicinfo?icao="+ppr.depIcao);
                this.setState({depInfo: responseDep.data})
            }
            if(ppr.arrIcao && ppr.arrIcao.length > 0) {
                const responseArr = await axios.get(baseUrl + "/api/airport/publicinfo?icao="+ppr.arrIcao);
                this.setState({arrInfo: responseArr.data})
            }
        }
    }
    

    mapOperationType(operationType) {
        if(operationType) {
            switch(operationType) {
                case "PPR_TC":
                    return "PPR";
                case "PPR":
                    return "PPR";
                case "PNR":
                    return "Notice";
            }
        } else {
            return "";
        }
    }

    mapOutcomeGeneric(outcome) {
            switch(outcome) {
                case 0:
                    return "Success";
                case 1:
                    return "Manual approval required. Please see details below."
                case 2:
                    return "Failure"
                case 3:
                    return "An error occured, please try again later";
            }
        return ""
    }
    mapOutcomeGenericWhenDepAndArrTheSame(outcome,operationMode) {
        var success = "Form successfully received"
        var failure = "Form rejected. Please see details below"
        if(operationMode === "PNR") {
            success = "Notice successfully received"
            failure = "Notice rejected"
        } else if(operationMode.includes("PPR")) {
            success = "Request successfully received and processed"
            failure = "Request denied"
        }
        switch(outcome) {
            case 0:
                return success;
            case 1:
                return "Request received but manual approval is required. Please see details below."
            case 2:
                return failure
            case 3:
                return "An error occured, please try again later";
        }
    return ""
}

    mapOutcome(outcome, outcomeReason, operationType, contactPhone) {
        let operationTypeStr =  this.mapOperationType(operationType);
            switch(outcome) {
                case 0:
                    if(operationType === "PNR") {
                        return operationTypeStr + " received"
                    } else {
                        return operationTypeStr + " approved";
                    }
                case 1:
                    return operationTypeStr + " requires manual approval contact: " + contactPhone;
                case 2:
                    return operationTypeStr + " rejected due to: " + outcomeReason;
                case 3:
                    return "An error occured, please try again later";
            }
        return ""
    }


    mapOutcomeToAlertVariant(outcome) {
        if(outcome != null) {
            switch(outcome) {
                case 0:
                    return "success";
                case 1:
                    return "warning";
                case 2:
                    return "danger";
                case 3:
                    return "danger";
            }
        }
        return ""
    }

    renderGenericResponse(outcome, pic,phone,email, dep, depIcao, arr, arrIcao,ifr) {
        let variant = this.mapOutcomeToAlertVariant(outcome);
        let ifrSnippet = ""
        if(ifr) {
            ifrSnippet = (<tr><td>IFR Arrival:</td><td>{ifr}</td></tr>)
        }
        var snippet = (
            <Alert variant={variant}>
                {
                
                }
                
                <h1>
                    {this.mapOutcomeGeneric(outcome)}
                </h1>
                <table>
                    <tbody>
                    <tr>
                        <td>PIC:</td>
                        <td>{pic}</td>
                    </tr>
                    <tr>
                        <td>Phone:</td>
                        <td>{phone}</td>
                    </tr>
                    <tr>
                        <td>email:</td>
                        <td>{email}</td>
                    </tr>
                    <tr>
                        <td>dep:</td>
                        <td>{depIcao} {dep}</td>
                    </tr>
                    <tr>
                        <td>arr:</td>
                        <td>{arrIcao} {arr}</td>
                    </tr>
                    {ifrSnippet}

                    </tbody>
                   
                </table>

            </Alert>

        );
        return snippet;
    }

    renderGenericResponseWhenDepAndArrTheSame(outcome,pic,phone,email,dep,depIcao,arr,arrIcao, depOutcome, depOutcomeReason, arrOutcome, arrOutcomeReason,contactPhoneDep,contactPhoneArr,operationMode,ifr) {
        let variant = this.mapOutcomeToAlertVariant(outcome);
        let depSnippet = ""
        let arrSnippet = ""
        if(depIcao && depIcao.length > 0 && depOutcome != 0) {
            depSnippet=this.mapOutcome(depOutcome,depOutcomeReason,operationMode,contactPhoneDep)
            
        }

        if(arrIcao && arrIcao.length > 0 && arrOutcome != 0) {
            arrSnippet = this.mapOutcome(arrOutcome,arrOutcomeReason,operationMode,contactPhoneArr)
        }

        let ifrSnippet = ""
        if(ifr) {
            ifrSnippet = (<tr><td>Selected IFR Arrival</td></tr>)
        }

        var snippet = (
            <Alert variant={variant}>
                {
                
                }
                
                <h1>
                    {this.mapOutcomeGenericWhenDepAndArrTheSame(outcome,operationMode)}
                </h1>
                <table>
                    <tbody>
                    <tr>
                        <td>PIC:</td>
                        <td>{pic}</td>
                    </tr>
                    <tr>
                        <td>Phone:</td>
                        <td>{phone}</td>
                    </tr>
                    <tr>
                        <td>email:</td>
                        <td>{email}</td>
                    </tr>
                    <tr>
                        <td>Dep:</td>
                        <td>{dep} {depSnippet}</td>
                    </tr>
                    
                    <tr>
                        <td>Arr:</td>
                        <td>{arr} {arrSnippet}</td>
                    </tr>
                    {ifrSnippet}
                    </tbody>
                   
                </table>

            </Alert>

        );
        return snippet;
    }

    renderSpecificResponse(outcome, outcomeReason, operationType, depOrArr, pic, phone, email, icao, time,contactPhone,contactEmail,freeText,ifr ) {
        let variant = this.mapOutcomeToAlertVariant(outcome);
        console.log("variant: " + variant);

        var contactSnippet = "";
        if(contactPhone && contactPhone.length > 0) {
            contactSnippet = "Aerodrome contact for " + icao + ":" + contactPhone;
        }
        var freeTextSnippet = "";
        if (freeText && freeText.length > 0) {
            freeTextSnippet = (
                
                <p>
                {freeText}
                </p>
            )
        }
        let ifrSnippet = ""
        if(ifr) {
            ifrSnippet = (<tr><td>IFR Arrival:</td><td>{ifr}</td></tr>)
        }
        var snippet = (
            <Alert variant={variant}>
                <h1> {depOrArr} </h1>
               
                
                <p>
                    {this.mapOutcome(outcome,outcomeReason,operationType,contactPhone)}
                </p>
                <table>
                    <tbody>
                    <tr>
                        <td>Location:</td>
                        <td>{icao}</td>
                    </tr>
                    <tr>
                        <td>Time:</td>
                        <td>{time}</td>
                    </tr>
                    <tr>
                    <td colSpan="2"> {contactSnippet}</td>
                    </tr>
                    <tr>
                        <td colSpan="2"> {freeTextSnippet}</td>
                    </tr>
                    {ifrSnippet}
                    </tbody>
                   
                </table>

            </Alert>

        );
        return snippet;
    }

    renderPrivateDataResponse(pilotId) {

        var linkText = baseUrl+"/ui/privatedata?pilotId="+pilotId

        var snippet = (
            <Alert variant="success">
                               
                <p>
                    To cancel your PPR/PN, bookmark and use this link:
                    <a href={linkText}>private view</a>
                </p>

            </Alert>

        );
        return snippet;

    }

    renderPrivateLinkResponse(pilotId,defaultAirport) {

        var linkText = ""
        if(defaultAirport) {
            linkText = baseUrl+"/ui/"+defaultAirport.toLowerCase()+"/request?pilotId="+pilotId;
        } else {
            linkText = baseUrl+"/ui/request?pilotId="+pilotId;
        }
        var snippet = (
            <Alert variant="success">
                               
                <p>
                    To submit a PPR/PN with your existing data (pic,email,mtow,phone), bookmark and use this link:
                    <a href={linkText}>privatelink</a>
                </p>

            </Alert>

        );
        return snippet;

    }

    

    render() {

        let data = this.props.location.state.data;
        let status = this.props.location.state.status;
        let defaultAirport = this.props.location.state.defaultAirport;


        console.log("data: " + data);
        console.log("status: " + status);

        let contactEmailDep = "";
        let contactPhoneDep = "";
        let freeTextDep = "";
        let contactEmailArr = "";
        let contactPhoneArr = "";
        let freeTextArr = "";
        let showApprovalWarning = "";

        if(this.state && this.state.depInfo) {
            contactEmailDep = this.state.depInfo.contactEmail;
            contactPhoneDep = this.state.depInfo.contactPhone;
            freeTextDep = this.state.depInfo.freeText;
        }

        if(this.state && this.state.arrInfo) {
            contactEmailArr = this.state.arrInfo.contactEmail;
            contactPhoneArr = this.state.arrInfo.contactPhone;
            freeTextArr = this.state.arrInfo.freeText;
        }

        


        const genericError = (
            <Container>
            <Col xs={12} fluid>
                <Container>
                    <Alert variant="danger">
                        Something went wrong, please try again later
                    </Alert>
                </Container>
            </Col>
        </Container>

            );

        if (status == 200) {
            if(data && data.approvalResponse && data.ppr) {
                let approvalResponse = data.approvalResponse;
                let ppr = data.ppr;
                let outcome = approvalResponse.outcome;
                let outcomeReason = approvalResponse.outcomeReason;
                let pic = ppr.pic;
                let phone = ppr.phone;
                let email = ppr.email;
                let dep = ppr.depDate + " " + ppr.dep;
                let depICAO = ppr.depIcao;
                let arr = ppr.arrDate + " " + ppr.arr;
                let arrICAO = ppr.arrIcao;
                let ifr = ppr.ifr;

                let depOperationType = approvalResponse.depOperationType;
                let arrOperationType = approvalResponse.arrOperationType;
                let depOutcome = approvalResponse.depOutcome;
                let arrOutcome = approvalResponse.ArrOutcome;
                let depOutcomeReason = approvalResponse.depOutcomeReason;
                let arrOutcomeReason = approvalResponse.arrOutcomeReason;
                console.log(JSON.stringify(approvalResponse));

            
                var depRender = null;
                var arrRender = null;

                var genericRender = this.renderGenericResponse(outcome,pic,phone,email,dep,depICAO,arr,arrICAO,ifr);

                var privateDataRender;
                var privateLinkRender;

                

                if(depOutcome != null && depICAO && dep && depOutcome != 0) {
                    console.log("DEP Condition passed");
                    depRender = this.renderSpecificResponse(depOutcome, depOutcomeReason,depOperationType,"Departure",pic,phone,email,depICAO,dep, contactPhoneDep, contactEmailDep, freeTextDep,ifr);
                }

                if(arrOutcome != null && arrICAO && arr && arrOutcome != 0) {
                    console.log("Arr condition passed");
                    arrRender = this.renderSpecificResponse(arrOutcome,arrOutcomeReason,arrOperationType,"Arrival",pic,phone,email,arrICAO,arr, contactPhoneArr,contactEmailArr, freeTextArr,ifr);
                }

                if(depICAO && arrICAO && depICAO.length > 0 && arrICAO.length > 0 && depICAO === arrICAO) {
                    genericRender = this.renderGenericResponseWhenDepAndArrTheSame(outcome,pic,phone,email,dep,depICAO,arr,arrICAO,depOutcome,depOutcomeReason,arrOutcome, arrOutcomeReason,contactPhoneDep,contactPhoneArr,depOperationType,ifr);
                    depRender = "";
                    arrRender = "";   
                }
                console.log("GOT HERE");

                var depOk = false
                var arrOk = false

                if(!depOutcome || depOutcome < 2) {
                    depOk = true
                }

                if(!arrOutcome || arrOutcome < 2) {
                    arrOk = true
                }

                if(depOk && arrOk) {
                    privateDataRender = this.renderPrivateDataResponse(data.pilotId);
                    privateLinkRender = this.renderPrivateLinkResponse(data.pilotId,defaultAirport);
                }

                return (
                    <Container>
                        <Row>
                        <Col xs={12}>
                            {genericRender}
                            </Col>
                        </Row>
                        <Row>
                        <Col xs={12}>
                            {depRender}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                            {arrRender}
                            </Col>
                        </Row>
                        <Row>
                            {privateDataRender}
                        </Row>
                        <Row>
                            {privateLinkRender}
                        </Row>
                    </Container>
                )


            } else {
                return genericError;
            }
            

        } else {
            return genericError;
        }

       
    }

}
export default PermissionStatus;