// Constants.js
const prod = {
    url: {
        API_URL: 'https://ppr.yleisilmailu.fi'
    }
};
      
    const dev = {
       url: {
        //API_URL: 'http://192.168.99.101:30700'
        API_URL: 'https://ppr.yleisilmailu.fi'
       }
      };
      export const config = process.env.NODE_ENV === 'development' ? dev : prod;