import React from 'react';
import {Modal,Button,Form} from 'react-bootstrap';

class NewSlotModal extends React.Component {

    handleClose() {

    }

    render() {
        if(this.props) {
            console.log("PROPS: " + JSON.stringify(this.props));
        }

        if(this.state) {
            console.log("STATE: " + JSON.stringify(this.state));
        }
 
        if(this.props) {
            return(
                <Modal show={this.props.show} onHide={this.props.onHide}>
            <Modal.Header closeButton>
              <Modal.Title>New TC Slot</Modal.Title>
            </Modal.Header>
            <Form>
            <Modal.Body>
            <Form.Group controlId="aircraftReg">
                <Form.Label>Aircraft Reg</Form.Label>
                <Form.Control required type="text" placeholder="OHXXX" />
                <Form.Control.Feedback type="invalid">
                  Please enter aircraft registration
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                Aircraft registration without special characters
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="pilot">
                <Form.Label>Pilot</Form.Label>
                <Form.Control required type="text" placeholder="Full Name" />
                <Form.Control.Feedback type="invalid">
                  Please enter name
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                Pilot
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" placeholder="someone@something.com" />
                <Form.Control.Feedback type="invalid">
                  Please enter valid email
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                Members: please enter the same email used in member registry to avoid unnecessary invoices
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="formStartTime">
                <Form.Label>Start time</Form.Label>
                <Form.Control required type="text" placeholder="Start time (hhmm)" />
                <Form.Control.Feedback type="invalid">
                  Start time required
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                Only 4 numbers allowed (hhmm), and minutes must be 00,15,30,45
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="formEndTime">
                <Form.Label>End time</Form.Label>
                <Form.Control required type="text" placeholder="End time (hhmm)" />
                <Form.Control.Feedback type="invalid">
                  End time required
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                Only 4 numbers allowed (hhmm), and minutes must be 00,15,30,45
                </Form.Text>
            </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.onHide}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleClose} type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
            </Form>
          </Modal>
            )
            
        }

        return "";
    }

}

export default NewSlotModal;