import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Container,Row,Col,Form,Button,Alert} from 'react-bootstrap';
import axios from 'axios';

class PprEFHF extends React.Component {
    constructor() {
        super();
        this.state = { validated: false, errorMessages: [] };
        this.handleSubmit = this.handleSubmit.bind(this);
      }

    handleSubmit(event) {
        const data = new FormData(event.target);
        console.log("handlesubmit called");
        event.preventDefault();
        
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
            let aircraftReg = form.elements.formGridRegistration.value

            
            axios.post(
                'http://localhost:8000/submitppr',
                { aircraftReg: form.elements.formGridRegistration.value,
                  mtow: form.elements.formGridMtow.value,
                  pic: form.elements.formGridPic.value,
                  phone: form.elements.formGridPhone.value,
                  dep: form.elements.formGridDep.value,
                  arr: form.elements.formGridArr.value,
                  email: form.elements.formGridEmail.value,
                  member: form.elements.formGridMember.value },
                { headers: { 'Content-Type': 'application/json' } }
              ).then((response) => {
                  console.log("RESPONSE")
                console.log(response);
              }, (error) => {
                  var messages = error.response.data.errorMessages;
                  this.setState({
                      errorMessages: messages
                  }); 
                console.log(JSON.stringify(error.response));
              });
              
        }
        
        this.setState({
            validated:true
        })

        let formTarget = event.target
        
      }

 

  render() {
      let validated = this.state.validated;

      var errorMessages = []
      let data = this.state.errorMessages;
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
           errorMessages.push(data[key].error);
        }
     }

     var errorView = errorMessages.map((error,idx) => (
            <Alert key={idx} variant='danger'>
            {error}
          </Alert>
        ))
    

    return (
        <Container>
            {errorView}
            <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridRegistration">
            <Form.Label>Aircraft registration (OHXYZ) (required): </Form.Label>
            <Form.Control required type="text" ref="aircraftReg" placeholder="Aircraft Registration" />
            <Form.Control.Feedback type="invalid">
              Please enter aircraft registration
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group controlId="formGridMtow">
                <Form.Label>MTOW (Kg)</Form.Label>
            <Form.Control required placeholder="MTOW in Kg" />
            <Form.Control.Feedback type="invalid">
              Please enter MTOW
            </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group controlId="formGridPic">
                <Form.Label>PIC (Full name, required): </Form.Label>
            <Form.Control required placeholder="PIC full name" />
            <Form.Control.Feedback type="invalid">
              Please enter PIC name
            </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group controlId="formGridPhone">
                <Form.Label>Phone (required): </Form.Label>
            <Form.Control required placeholder="Phone number" />
            <Form.Control.Feedback type="invalid">
              Please enter phone number
            </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group controlId="formGridDep">
                <Form.Label>DEP (local HHMM):  </Form.Label>
            <Form.Control placeholder="If departing from EFHF, enter dep time here" />
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group controlId="formGridArr">
                <Form.Label>ARR (local HHMM):  </Form.Label>
            <Form.Control placeholder="If arriving at EFHF, enter arr time here" />
            </Form.Group>
        </Form.Row>

           <Form.Row>
            <Form.Group controlId="formGridEmail">
                <Form.Label>PIC e-mail (for billing / laskutusta varten):</Form.Label>
            <Form.Control required type="email" placeholder="Email address" />
            <Form.Control.Feedback type="invalid">
              Please enter email
            </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
      
        <Form.Group id="formGridMemberCheckbox" controlId="formGridMember">
          <Form.Check type="checkbox" label="I am a member of Malmi Airfield Association / Olen Malmin lentokenttäyhdistyksen jäsen: " />
        </Form.Group>
      
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>

        </Container>
        
    );
  }
  
}

export default PprEFHF;
