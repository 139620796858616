import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import {Table,Alert,Container,Row,Col,Button,Modal,Form} from 'react-bootstrap';
import axios from 'axios';
import { config } from '../Constants'
import querySearch from "stringquery";
//import { withRouter } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const withLocation = Component => props => {
    const location = useLocation();
  
    return <Component {...props} location={location} />;
  };



var baseUrl = config.url.API_URL
class PrivateView extends React.Component {
    constructor() {
        super();
        this.state = { validated: false, errorMessages: [] };
        this.confirmCancel = this.confirmCancel.bind(this);
        this.closeCancelModal = this.closeCancelModal.bind(this);
      }
    
    async componentDidMount() {
        this.loadPrivateData()
    }

    async loadPrivateData() {

        
        console.log(JSON.stringify(this.state));
        console.log("props: " + JSON.stringify(this.props.location.search));
        const queryParams = new URLSearchParams(this.props.location.search);
        //const queryParam = queryParams.toString();
        
        //console.log('Query Parameter:', queryParam);
          
          if(queryParams) {
            const pilotId = queryParams.get("pilotId");
            console.log("GOT PILOT ID: " + pilotId)
            const pilotInfoResp = await axios.get(baseUrl + "/api/pilot/privatedata?pilotId="+pilotId)
            const data = pilotInfoResp.data;
            
            if(pilotInfoResp.data) {
              this.setState(
                {
                  privateData: data,
                  pilotId: pilotId
                }
              )
            } else {
                this.props.history.push({
                    pathname: '/ui'
                  })
            }
          } else {
            this.props.history.push({
                pathname: '/ui'
              })
        }

    }


    parseDate(timeString) {
        var millis = Date.parse(timeString);
        var parsedDate = new Date(millis);
        var minutes = parsedDate.getMinutes();
        if(minutes < 10) {
          minutes = "0"+minutes;
        }
        var hours = parsedDate.getHours();
        if(hours < 10) {
          hours = "0"+hours;
        }
        return "" + hours + "" + minutes;
      }
  
      renderRow(item,opTime,depArr,pilotId) {
        return (
          <tr>
            <td>{item.id}</td>
            <td>{depArr}</td>
            <td>{opTime}</td>
            <td>{item.aircraftReg}</td>
            <td>{item.pic}</td>
            <td>{item.mtom}</td>
            <td>{item.icao}</td>
            <td>{item.email}</td>
            <td>{item.phone}</td>
            <td>{item.opStatus}</td>
            <td>{item.permType}</td>
            <td><Button variant="danger" onClick={this.handleCancel.bind(this,item,pilotId)}>Cancel</Button></td>
          </tr>
        )
      }

      renderTCRow(item,opTime,depArr,pilotId) {
        return (
          <tr>
            <td>{item.id}</td>
            <td>{depArr}</td>
            <td>{opTime}</td>
            <td>{item.aircraftReg}</td>
            <td>{item.pic}</td>
            <td>{item.icao}</td>
            <td>{item.email}</td>
            <td>{item.opStatus}</td>
            <td>{item.permType}</td>
            <td><Button variant="danger" onClick={this.handleCancel.bind(this,[item,pilotId])}>Cancel</Button></td>
          </tr>
        )
      }

      handleCancel(items) {
        console.log("items: " + items);
        this.setState({
          openCancelModal: true,
          cancelItem: items[0],
          pilotId: items[1]
       })
      }

      confirmCancel(items) {
        var serviceUrl = '/api/pilot/cancel'
        const localItem = items[0];
        const localPilotId = items[1];

        console.log("items in confirmCancel: " + items);
        if(localItem.opType == "TC") {
          serviceUrl = 'api/pilot/canceltd'
        }
        console.log("ITEMS: " + items);
        
        axios.post(
          baseUrl + serviceUrl,
          { id: localItem.id,
            uuid: localItem.uuid,
            pilotId: localPilotId
            },
          { headers: { 'Content-Type': 'application/json' } }
        ).then((response) => {
            this.loadPrivateData()
          
        }, (error) => {
          console.log(JSON.stringify(error));
          if(error.response && error.response.data) {
            this.setState({
                isError: true,
                errorMessage: error.response.data
            })
            
          } else {
            this.setState({
                isError: true,
                errorMessage: "Unexpected error, please try again later"
            })
          }
         
        });
        console.log("CANCEL CONFIRMED");
        this.closeCancelModal();
      }

      closeCancelModal() {
        this.setState({
          openCancelModal: false,
       })
      }

      handleEdit(item) {
        this.setState({
          editRow: true,
          editItem: item,
          openEditModal: true
       })
      }

 

  render() {

    var flightOps = []
    var tcOps = []
    var pilotId = this.state.pilotId;
    console.log("pilotID in render: " + JSON.stringify(pilotId));

    var cancelModal = "";
    if(this.state && this.state.openCancelModal && this.state.cancelItem) {
      cancelModal = (<CancelModal
        show={this.state.openCancelModal}
        onHide={this.closeCancelModal} saveHandler={this.confirmCancel}
        item={[this.state.cancelItem,this.state.pilotId]}
      />)
    }

    if(this.state && this.state.privateData) {
        if(this.state.privateData.flightOperations) {
          flightOps = this.state.privateData.flightOperations.filter(function (item) {
            return item.opStatus === "APPROVED" || item.opStatus === "PENDING";
          });
        }
        
        if(this.state.privateData.tcOperations) {
          tcOps = this.state.privateData.tcOperations.filter(function (item) {
            return item.opStatus === "APPROVED" || item.opStatus === "PENDING";
          });
        }
        
    }

    var errorView = ""
    if(this.state && this.state.isError) {
        errorView = (<Alert variant="danger">{this.state.errorMessage}</Alert>)
    }

    return ( <Container fluid>
        {cancelModal}
        {errorView}
       <Row>
         <Col><Alert variant="success">Flight operations </Alert></Col>
       </Row>
       <Row>
         <Col>
         <Table>
           <thead>
             <tr>
             <th>#</th>
          <th>dep/arr</th>
          <th>time</th>
          <th>Aircraft Reg</th>
          <th>Pic</th>
          <th>mtow</th>
          <th>location</th>
          <th>email</th>
          <th>phone</th>
          <th>status</th>
          <th>permission</th>
          <th>cancel</th>
             </tr>
           </thead>
           <tbody>
             { 
               flightOps.map((item, index) => {
                  
                   return (
                     <tr>
                       <td>{item.id}</td>
                       <td>{item.opType}</td>
                       <td>{this.parseDate(item.opTime)}</td>
                       <td>{item.aircraftReg}</td>
                       <td>{item.pic}</td>
                       <td>{item.mtom}</td>
                       <td>{item.icao}</td>
                       <td>{item.email}</td>
                       <td>{item.phone}</td>
                       <td>{item.opStatus}</td>
                       <td>{item.permType === "PNR" ? "PN" : item.permType}</td>
                       <td><Button variant="danger" onClick={this.handleCancel.bind(this,[item,pilotId])}>Cancel</Button></td>
                     </tr>
                   )
               })
             }
           </tbody>
         
         </Table>
         </Col>
       </Row>
       <Row>
         <Col><Alert variant="success">TC Operations </Alert></Col>
       </Row>
       <Row>
         <Col>
         <Table>
           <thead>
             <tr>
             <th>#</th>
          <th>dep/arr</th>
          <th>time</th>
          <th>Aircraft Reg</th>
          <th>Pic</th>
          <th>location</th>
          <th>email</th>
          <th>status</th>
          <th>permission</th>
          <th>cancel</th>
             </tr>
           </thead>
           <tbody>
             { 
               tcOps.map((item, index) => {
                      var opTimeStart = this.parseDate(item.start)
                      var opTimeEnd = this.parseDate(item.end)
                      return [this.renderTCRow(item,opTimeStart,"TC DEP",pilotId),this.renderTCRow(item,opTimeEnd,"TC ARR",pilotId)]
               })
             }
           </tbody>
         
         </Table>
         </Col>
       </Row>
     </Container>)
  }
     
}

function CancelModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cancel Operation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Confirm Cancellation</h4>
          
          <p>
            Are you sure you want to cancel this operation ? 
          </p>
          <p>
          id {props.item[0].id}, {props.item[0].aircraftReg}, {props.item[0].pic}, {props.item[1]}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button onClick={props.saveHandler.bind(this,[props.item[0],props.item[1]])}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  }

export default withLocation(PrivateView);
