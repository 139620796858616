import React from 'react';
import {Container,Jumbotron,Alert,Row,Col} from 'react-bootstrap';
import axios from 'axios';
import { config } from '../Constants'

var baseUrl = config.url.API_URL

class PrivacyPolicy extends React.Component {

    render() {
        return (
         <div>

            <p>Tämä on&nbsp;Suomen Yleisilmailu ry:n&nbsp;henkilötietolain (10 ja 24 §) ja EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste. Laadittu&nbsp;1.1.2021. Viimeisin muutos&nbsp;21.05.2021.</p>

            <h2> Muutokset 21.05.2021.</h2>
            <p> Numerointia korjattu, lisätty kohdat 1 ja 8. Päivitetty kohdat 6 ja 7</p>

            <h2>1. Rekisterinpitäjä</h2>
            <p>Suomen Yleisilmailu  ry</p>
        
            <h2>2. Rekisterin nimi</h2>

            <p>
            Suomen Yleisilmailu ry:n PPR-rekisteri</p>
            <p>
            Suomen Yleisilmailu ry:n laskutusjärjestelmä
            </p>

            <h2>3. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h2>
            <p>Jättämällä jäsenhakemuksen, kausikorttitilauksen, PPR-pyynnön tai laskukierrosvarauksen Suomen Yleisilmailu ry:lle rekisteröity antaa suostumuksensa henkilötietojen käsittelyyn. Henkilötietojen käsittely on tarpeen osapuolten välille syntyvän sopimuksen täytäntöön panemiseksi sekä rekisterinpitäjän lakisääteisten velvoitteiden noudattamiseksi.</p>
            <p>Henkilötietojen käsittelyn tarkoitus on&nbsp;yhteydenpito asiakkaisiin, asiakassuhteen ylläpito, laskutus sekä lentopaikkojen liikenteen tilastointi ja turvallisuuden seuranta.</p>
            <p>Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin.</p>
            <h2>4. Rekisterin tietosisältö</h2>
            <p>Rekistereihin tallennettavia tietoja ovat:&nbsp;henkilön nimi, yritys/organisaatio, yhteystiedot (puhelinnumero, sähköpostiosoite, osoite), verkkoyhteyden IP-osoite, tiedot tilatuista palveluista ja niiden muutoksista, laskutustiedot, ilma-aluksen rekisteritunnus sekä muut asiakassuhteeseen ja tilattuihin palveluihin liittyvät tiedot.</p>
            <h2>5. Säännönmukaiset tietolähteet</h2>
            <p>Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. www-lomakkeilla lähetetyistä viesteistä, sähköpostitse, puhelimitse, sosiaalisen median palvelujen kautta, sopimuksista, asiakastapaamisista ja muista tilanteista, joissa asiakas luovuttaa tietojaan.</p>
            <h2>6. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle</h2>
            <p>Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja voidaan julkaista siltä osin kuin niin on sovittu asiakkaan kanssa.</p>
            <p>Rekisterinpitäjä ei siirrä tietoja EU:n tai ETA:n ulkopuolelle. Ainoastaan rekisterinpitäjän käyttämä ulkoinen Google Sheets / Google Docs palvelu saattaa säilyttää tietoja EU:n tai ETA:n ulkopuolella.</p>
            <p>Tietoja tallennetaan Google LLC:n ja DigitalOcean, Inc:in ylläpitämiin palveluihin.</p>
            <h2>7. Rekisterin suojauksen periaatteet</h2>
            <p>Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan asianmukaisesti. Kun rekisteritietoja säilytetään Internet-palvelimilla, niiden laitteiston fyysisestä ja digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti. Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu.</p>
            <h2>8. Tietojen säilytyksen ajankohta</h2>
            <p>Rekisterinpitäjä säilyttää henkilötietoja enintään 12kk. Tiedot poistetaan mahdollisuuksien mukaan myös aiemmin mikäli tarvetta ei ole laskutuksen näkökulmasta. Ainoastaan anonyymit tilastotiedot (kuukausikohtainen operaatioiden määrä) saatetaan säilyttää pidempään, mikäli lentokentänpitäjä näin toivoo.</p>
            <h2>9. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h2>
            <p>Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee&nbsp;lähettää kirjallisesti&nbsp;rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p>
            <h2>10. Muut henkilötietojen käsittelyyn liittyvät oikeudet</h2>
            <p>Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä (”oikeus tulla unohdetuksi”). Niin ikään rekisteröidyillä on muut&nbsp;<a href="http://eur-lex.europa.eu/legal-content/FI/TXT/?uri=CELEX%3A32016R0679#d1e2144-1-1">EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet</a>&nbsp;kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa. Pyynnöt tulee&nbsp;lähettää kirjallisesti&nbsp;rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p>
        
         </div>   
        );
    }
}

export default PrivacyPolicy