import React, { useRef }  from 'react';
import ReactDOM from 'react-dom';
import {Container,Row,Col,Form,Button,Alert,Spinner,Card, Dropdown} from 'react-bootstrap';
import axios from 'axios';
import { config } from '../Constants'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import NewSlotModal from './newslotmodal'
import fiLocale from '@fullcalendar/core/locales/fi';
import './Tc.scss'
import { isArraysEqual } from '@fullcalendar/core';
import TrafficCircuitStatus from './TcStatus'
var baseUrl = config.url.API_URL

class Tc extends React.Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef()  
    this.state = { validated: false, errorMessages: [] };
  }

  async componentDidMount() {
    
      
    const response = await axios.get(baseUrl + "/api/airport/find")

    let icaoArray = response.data.map(airport => airport.icao)

    let icaoHoursMap = new Map()
    let icaoExceptionHoursMap = new Map()
    var icaoCodes = []
    
    var icaoAdditionalPropertiesMap = new Map()
  
    if(response.data && Array.isArray(response.data)) {
      response.data.map(airport => {
        console.log(airport.icao)
        axios.get(baseUrl + "/api/airport/approvalinfo", {
          params: {
            icao: airport.icao
          }
        }).then(res => {
          if(res.status == 200) {
            if(res.data) {
              res.data.operatingHours.forEach( opHour => {
                if(opHour.typeOfOperation == "TC") {
                    icaoHoursMap.set(res.data.airport.icao,opHour)
                    icaoCodes.push(res.data.airport.icao)

                    var additionalPropertiesMap = new Map()
                    res.data.additionalProperties.forEach( addData => {
                      console.log("ADDDATA: " + JSON.stringify(addData))
                      additionalPropertiesMap.set(addData.propertyKey, addData.propertyValue)
                    })
      
                    icaoAdditionalPropertiesMap.set(res.data.airport.icao, additionalPropertiesMap)


                    console.log("RESULT MAP: " + additionalPropertiesMap.keys())
                    this.setState(
                    {
                      icaoHoursMap: icaoHoursMap,
                      icaoCodes: icaoCodes,
                      additionalProps: icaoAdditionalPropertiesMap,
                      selectedAirport: this.props.defaultAirport
                    }
                  )
                }
              })

              if(res.data.exceptionalHours) {
                res.data.exceptionalHours.forEach( exceptHour => {
                  if(exceptHour.typeOfOperation == "TC") {
                      icaoExceptionHoursMap.set(res.data.airport.icao,exceptHour)
                      
                  }
                })

                this.setState(
                  {
                    icaoExceptionHoursMap: icaoExceptionHoursMap
                    
                  })
              }
              

             
            }
          } else {
            this.setState({
              error: true,
              errorMessage: res.data
            })
          }
       
        })

      })

    }

      this.interval = setInterval(() => this.loadCurrentDayOperations(), 5000);
    
   
  }


  async componentWillUnmount() {
    clearInterval(this.interval)
  }

  closeModal() {
    this.setState({
      showPopup:false,
      selectedDate:null
    })
  }

  handleSubmit(event) {
    const data = new FormData(event.target);
    console.log("handlesubmit called");
    event.preventDefault();
    
    console.log("selectedAirport: " + this.state.selectedAirport);

    const form = event.currentTarget;

    if(!this.state.selectedAirport) {
      this.setState({
        errorMessages: [{"error":"Please select an airport"}]
      });
      event.stopPropagation(); 
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
        let aircraftReg = form.elements.formGridRegistration.value
        
        axios.post(
            baseUrl + '/api/tc/submit',
            { aircraftReg: form.elements.formGridRegistration.value,
              pic: form.elements.formGridPic.value,
              icao: this.state.selectedAirport,
              start: form.elements.formGridStart.value,
              end: form.elements.formGridEnd.value,
              email: form.elements.formGridEmail.value
              },
            { headers: { 'Content-Type': 'application/json' } }
          ).then((response) => {
              /*console.log("RESPONSE")
            console.log(JSON.stringify(response.data));
            this.props.history.push({
              pathname: '/ppr/tcstatus',
              state: { 
                approvalResponse: response.data,
                approvalHttpStatus: response.status 
            }
            })*/
            this.setState({
                approvalResponse: response.data,
                approvalHttpStatus: response.status,
                showApprovalBox: true,
                validated:false,
                hasError:false
            })

            ReactDOM.findDOMNode(this.myRef).reset();
          }, (error) => {
            

              if(!error.response || !error.response.data) {
                this.setState({
                  errorMessages: ["Something went wrong"]
              }); 
              }
              console.log("ERRMSG: " + error.response.data.errorMessages);
              if(error.response.data.errorMessages) {
                  var messages = error.response.data.errorMessages;
                  console.log("ERROR: " + messages);
                  this.setState({
                    errorMessages: messages,
                    hasError:true
                }); 
              } else {
                
                this.setState({
                  errorMessages: [{"error":error.response.data}],
                  hasError:true
              }); 
              }

             

             // ReactDOM.findDOMNode(this.myRef).reset();
             
              
            console.log(JSON.stringify(error.response));
          });
          
    }
    
    this.setState({
        validated:true
    })

    let formTarget = event.target
    
  }

  async loadCurrentDayOperations() {
    if(this.state.selectedAirport) {
      console.log("load current day ops")
      const response = await axios.get(baseUrl + "/api/tc/currentdayoperations?icao="+this.state.selectedAirport)
    console.log(response.data)

    var events = [];
    if(response.data && response.data.publicTcOperations) {
      let tcOperations = response.data.publicTcOperations
      tcOperations.forEach( tcOp => {
        var eventColor = 'blue'
        if(tcOp.opStatus == "APPROVED") {
          eventColor = 'green'
        } else if(tcOp.opStatus == "PENDING") {
          eventColor = 'yellow'
        }

        console.log(JSON.stringify(tcOp))
        events.push({
          title:tcOp.aircraftReg,
          start: new Date(tcOp.start),
          end: new Date(tcOp.end),
          color: eventColor
        })
      })
      
       

      this.setState ({
        events: events
      })
    }
    }
    
  }

  async onAirportSelected(key) {
    
    this.setState({
      selectedAirport:key
    })

   this.loadCurrentDayOperations(key)
  
  }

  formatToFullCalendarFormat(timeStr,start) {
    var res = timeStr.split("-");
  
    var begin = res[0]
    var end = res[1]
  
    if(start) {
      return begin.substring(0,2)+":"+begin.substring(2,4)
    } else {
      return end.substring(0,2)+":"+end.substring(2,4)
    }

    
  }
  render() {

    var fullCalendar = "";
    var form = "Please select an airport from the dropdown menu"
    var tcOpen = true;
    var tcClosedReason = ""
    if(this.state && this.state.icaoHoursMap && this.state.icaoCodes.length > 0) {
    
      let validated = this.state.validated
      var slotLabelInterval = "00:15"
      var startTime = "07:00"
      var endTime = "22:00"
      

      

      if(this.state.selectedAirport && this.state.additionalProps) {

      
        

        var addProps = this.state.additionalProps.get(this.state.selectedAirport);
       
        if(addProps) {
          if(addProps.has("TC_SLOT_LENGTH")) {
            slotLabelInterval = "00:" + addProps.get("TC_SLOT_LENGTH");
          } else {
            slotLabelInterval = "00:15";
          }
  
          var oprHours = this.state.icaoHoursMap.get(this.state.selectedAirport);

          
         
  
          var d = new Date()
  
          if(d.getDay() == 0) {
            startTime = this.formatToFullCalendarFormat(oprHours.sunday, true);
            endTime = this.formatToFullCalendarFormat(oprHours.sunday, false);
          } else if(d.getDay() == 6) {
            startTime = this.formatToFullCalendarFormat(oprHours.saturday, true);
            endTime = this.formatToFullCalendarFormat(oprHours.saturday, false);
          } else {
            startTime = this.formatToFullCalendarFormat(oprHours.weekdays, true);
            endTime = this.formatToFullCalendarFormat(oprHours.weekdays, false);
          }



          if(this.state.icaoExceptionHoursMap) {
            var exceptionalHours = this.state.icaoExceptionHoursMap.get(this.state.selectedAirport);
            console.log("Exception hours in render: " + JSON.stringify(exceptionalHours))
            if(exceptionalHours.exceptionHours == "CLOSED") {
              tcOpen = false;
              tcClosedReason = exceptionalHours.exceptionDescription
            } else {
              startTime = this.formatToFullCalendarFormat(exceptionalHours.exceptionHours, true);
              endTime = this.formatToFullCalendarFormat(exceptionalHours.exceptionHours, false);
            }
          }


          var headerView = {start: 'title',center: '',end: '' }
  
          if(tcOpen) {

            fullCalendar = (  <FullCalendar events={this.state.events} slotEventOverlap={false} headerToolbar={headerView} slotLabelInterval={slotLabelInterval} dateClick={this.handleDateClick.bind(this)} locale='en-fi' slotMinTime={startTime} slotMaxTime={endTime} slotDuration={slotLabelInterval} initialView="timeGridDay" plugins={[ timeGridPlugin,interactionPlugin ]} slotLabelFormat= {[
              { hour: '2-digit', minute: '2-digit', omitZeroMinute:false, meriediem: 'long' }, // top level of text
              { hour: '2-digit', minute: '2-digit', omitZeroMinute:false, meriediem: 'long' } // lower level of text
              ]} />);
            console.log("startTime: " + startTime + " -- " + endTime);

          } else {
            fullCalendar = (<h4> Traffic Circuit for {this.state.selectedAirport} is closed due to {tcClosedReason} </h4>)
          }
          
          

        }
        
      }

      

      var icaoArray = Array.from(this.state.icaoHoursMap.keys()); 
      console.log(icaoArray)
      var airportOptions = this.state.icaoCodes.map(icao => (<Dropdown.Item key={icao} eventKey={icao}>{icao}</Dropdown.Item>))
      console.log(airportOptions)

      var errorMessages = []
      let data = this.state.errorMessages;
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
           errorMessages.push(data[key].error);
        }
     }
     
     var approvalBox=""
     if(this.state.approvalResponse && this.state.approvalHttpStatus && this.state.showApprovalBox) {
       approvalBox = (<TrafficCircuitStatus approvalResponse={this.state.approvalResponse} approvalHttpStatus={this.state.approvalHttpStatus}/>)
     }

     if(this.state.selectedAirport) {
       var hasError = false;
       if(this.state.hasError) {
         hasError = true;
       }

    
        var className = hasError ? "form-control is-invalid": "form-control"
    

       if(tcOpen) {
        form = (  <Form  id="tcForm" ref={form => this.myRef = form} noValidate validated={validated} onSubmit={this.handleSubmit.bind(this)}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridRegistration">
            <Form.Label>Aircraft registration (OHXYZ) (required): </Form.Label>
            <Form.Control className={className} required type="text" ref="aircraftReg" placeholder="Aircraft Registration" />
            <Form.Control.Feedback type="invalid">
              Please enter aircraft registration
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group controlId="formGridPic">
                <Form.Label>PIC (Full name, required): </Form.Label>
            <Form.Control className={className} required placeholder="PIC full name" />
            <Form.Control.Feedback type="invalid">
              {hasError ? "Error, form not submitted, please check errors below" : "Please enter PIC name"}
            </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group controlId="formGridEmail">
                <Form.Label>PIC e-mail (for billing / laskutusta varten):</Form.Label>
            <Form.Control className={className} required type="email" placeholder="Email address" />
            <Form.Control.Feedback type="invalid">
            {hasError ? "Error, form not submitted, please check errors below" : "Please enter valid email"}
            </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group controlId="formGridStart">
                <Form.Label>Start (local HHMM):  </Form.Label>
            <Form.Control className={className} required maxLength="4" minLength="4" type="number" placeholder="Enter start time here" defaultValue={this.state.start}/>
            <Form.Control.Feedback type="invalid">
            {hasError ? "Error, form not submitted, please check errors below" : " Please enter a valid start time (HHMM)"}
            </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>

        <Form.Row>
            <Form.Group controlId="formGridEnd">
                <Form.Label>End (local HHMM):  </Form.Label>
            <Form.Control className={className} required maxLength="4" minLength="4" type="number" placeholder="Enter end time here" defaultValue={this.state.end}/>
            <Form.Control.Feedback type="invalid">
            {hasError ? "Error, form not submitted, please check errors below" : " Please enter a valid end time (HHMM)"}
            </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>

        <Button variant="primary" type="submit">
          Submit
        </Button>
       
        </Form>)

       } else {
         form = ""
       }
        
     }

     var dropDown = ""

     if(!this.props.defaultAirport) {
        dropDown = ( <Row className="justify-content-md-center ">
        <Col xs lg="10">
          <Dropdown onSelect={this.onAirportSelected.bind(this)}>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {this.state.selectedAirport || "Select Airport"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {airportOptions}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>)
     }

    

     var errorView = errorMessages.map((error,idx) => (
            <Alert key={idx} variant='danger'>
            {error}
          </Alert>
        ))
      return (
        <Container >
          <Row className="justify-content-md-center">
            <Col xs lg="10">
            {approvalBox}
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs lg="10">
            <h1>
              TC Slot calendar {this.state.selectedAirport}
            </h1>
            </Col>
          </Row>
        {dropDown}
         
        
       <Row className="justify-content-md-center rowborder">
       <Col xs lg="10">
            {form}
        
            </Col>
       </Row>
       <Row className="justify-content-md-center">
            <Col xs lg="10">
            {errorView}
            </Col>
        </Row>
       <Row className="justify-content-md-center">
       <Col xs lg="10">
        {fullCalendar}
            </Col>
       </Row>
      
        </Container>
      );

    } else {
      return (
        <Container>
          <Row>
            <Col>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          </Row>
         
        </Container>
        
      )
    }
   
  }

  handleDateClick = (arg) => { 
    console.log(arg.dateStr)
    var date = new Date(Date.parse(arg.dateStr))

    console.log(date)
    let endDate = new Date(date)
    endDate.setMinutes(date.getMinutes() + 20)
    
    var hour = date.getHours()
    var minute = date.getMinutes()

    var endHour = endDate.getHours()
    var endMinute = endDate.getMinutes()
    

    if(hour < 10) {
      hour = "0"+hour;
    }

    if(minute < 10) {
      minute = "0"+minute
    }

    if(endHour < 10) {
      endHour = "0"+endHour;
    }

    if(endMinute < 10) {
      endMinute = "0"+endMinute
    }

    console.log(hour+minute)
    console.log(endHour+":"+endMinute)
    this.setState({
      updateForm:true,
      start:hour+""+minute+"",
      end:endHour+""+endMinute+""
    })

   
  }
  
}

export default Tc;
