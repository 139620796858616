import React from 'react';
import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import {Container,Row,Col} from 'react-bootstrap';
import PrivacyPolicy from './request/privacypolicy'
import { config } from './Constants'
import { Link } from 'react-router-dom';
var baseUrl = config.url.API_URL
class App extends React.Component {

  async componentDidMount() {
      
    const response = await axios.get(baseUrl + "/api/airport/find")
    this.setState({airports: response.data})

    if(this.props && this.props.defaultAirport) {
      const infoResp = await axios.get(baseUrl + "/api/airport/publicinfo?icao=" + this.props.defaultAirport)
      if(infoResp.data) {
        this.setState({
          specific:true,
          airport: infoResp.data
        })
      }
    }
  }
  
  convertOpType(airport) {
    if(airport.operationMode === "PNR") {
      return "PN"
    }
    return airport.operationMode;
  }
  render() {

    var pprSection = "";
    if(this.state && this.state.airports) {

      var headerPart="";
      var requestPart="";
      var tcPart="";

      if(this.state.specific && this.state.airport) {
        headerPart = (
          <div>
        <Row>
          <Col>
          <h2>{this.state.airport.displayName} - {this.state.airport.icao} ({this.convertOpType(this.state.airport)})</h2>
          </Col>
        </Row>
          <Row>
            <Col>
              <h4>{this.state.airport.freeText}</h4>
            </Col>
          </Row>
          
        
        </div>
        )

        requestPart=(
        <Row>
          <Col className="ubba">
            <a href={'/ui/'+this.state.airport.icao.toLowerCase()+'/request'}><h4>Flight operation</h4></a>
          </Col>
        </Row>
        )

        if(this.state.airport.operationMode.includes("TC")) {
         
          tcPart=(
            <Row>
            <Col>
              <a href={'/ui/'+this.state.airport.icao.toLowerCase()+'/tc'}><h4>Traffic circuit</h4></a>
            </Col>
          </Row>
          )
        }
        
      } else {
        headerPart = (
          <Row>
          <Col>
          <h4>PPR / PN</h4>
          </Col>
        </Row>
        )

        requestPart = this.state.airports.map((airport, index) => {
          console.log(JSON.stringify(airport))
          return (
            <Row>
              <Col>
              <a href={'/ui/'+airport.icao.toLowerCase()+'/request'}>{airport.displayName} - {airport.icao}</a>
              </Col>
            </Row>
          )
        })

        tcPart = ( <Row>
          <Col>
          <a href={'/ui/efhf/tc'}>TC calendar</a>
          </Col>
        </Row>
        )
      }
      }

    
   

    return (
    <Container>
     {headerPart}
      {requestPart}
      {tcPart}
      <Row>
        <Col>
        <Link to="/ui/privacypolicy"> Privacy policy / Tietosuojaseloste</Link>
        </Col>
      </Row>
    </Container>
  );

  }
  
}

export default App;
