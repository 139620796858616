import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import {Container,Row,Col,Form,Button,Alert,Spinner} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import axios from 'axios';
import { config } from '../Constants'
import querySearch from "stringquery";
import { options } from '@fullcalendar/core/preact';

var baseUrl = config.url.API_URL


const PermissionRequest = props => {
  const navigate = useNavigate();
  const location = useLocation()

  return <PermissionRequestOld navigate={navigate} location={location} {...props} />
}

class PermissionRequestOld extends React.Component {
    constructor() {
        super();
        this.state = { validated: false, errorMessages: [], defaultDate: new Date() };
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
    async componentDidMount() {
      if(this.props && this.props.defaultAirport === "EFNS") {
        this.setState({
          showDepRunway:true,
          showArrRunway:true
        })
      }

      if(this.props && this.props.defaultAirport === "EFNU") {
        this.setState({
          showIFRCheckbox:true
        })
      }
      const response = await axios.get(baseUrl + "/api/airport/find")
      const filtertedResp = response.data.filter(ad => ad.icao !== "ZZZZ")
    
      
      if(this.props.defaultAirport) {
        const infoResp = await axios.get(baseUrl + "/api/airport/publicinfo?icao=" + this.props.defaultAirport)
        console.log(infoResp.data)
        
        this.setState(
          {
            airports: filtertedResp,
            adInfo: infoResp.data
          }
          )
      } else {
        this.setState(
          {
            airports: filtertedResp,
          }
          )
      }
        
          const queryParam = querySearch(this.props.location.search);
          console.log("QUERYPARAM: " + queryParam);
          if(queryParam && queryParam.pilotId) {
            console.log("GOT PILOT Id: " + queryParam.pilotId)
            const pilotInfoResp = await axios.get(baseUrl + "/api/pilot/pilotinfo?pilotId="+queryParam.pilotId)
            if(pilotInfoResp.data) {
              this.setState(
                {
                  defaultPic: pilotInfoResp.data.pic,
                  defaultEmail: pilotInfoResp.data.email,
                  defaultPhone: pilotInfoResp.data.phone,
                  defaultAircraft: pilotInfoResp.data.defaultAircraft,
                  defaultMtow: pilotInfoResp.data.mtow,
                  defaultDate: new Date()
                }
              )
            }
           
          }
     
      
      console.log("RESPONSE: " + JSON.stringify(response));
      
    }

    handleDepIcaoChange(event) {
      if(event && event.target && event.target.value) {
        if(event.target.value === "EFNS") {
          this.setState({
            showDepRunway:true
          })
        } else {
          this.setState({
            showDepRunway:false
          })
        }
      }
    }

    handleArrIcaoChange(event) {
      if(event && event.target && event.target.value) {
        if(event.target.value === "EFNS") {
          this.setState({
            showArrRunway:true
          })
        } else {
          this.setState({
            showArrRunway:false
          })
        }
      }

      if(event.target.value === "EFNU") {
        this.setState({
          showIFRCheckbox:true
        })
      } else {
        this.setState({
          showIFRCheckbox:false
        })
      }
    }

    formatDate(dateOrStr) {
      var date = new Date(Date.parse(dateOrStr.toString()));
      console.log("dateOrStr: " + dateOrStr);
      console.log("Parsed Date: " + date);
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const formattedDate = date.toLocaleDateString('fi-FI', options).replace(/(\d+)\/(\d+)\/(\d+)/, "$2.$1.$3");
      return formattedDate;
    }

    handleSubmit(event) {
        const data = new FormData(event.target);
        console.log("handlesubmit called");
        event.preventDefault();
        
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
            let aircraftReg = form.elements.formGridRegistration.value
            var depRunway = form.elements.formGridDepRunway ? form.elements.formGridDepRunway.value : "";
            var arrRunway = form.elements.formGridArrRunway ? form.elements.formGridArrRunway.value : "";
            var ifrChecked = form.elements.IFR ? form.elements.IFR.checked : false;

            var depIcao = form.elements.formGridDepIcao.value
            if (depIcao && depIcao === "Other") {
              depIcao = "ZZZZ"
            }
            var dep = form.elements.formGridDep.value
            if(!dep || dep.length == 0) {
              depIcao=""
            }

            var arrIcao = form.elements.formGridArrIcao.value
            if (arrIcao && arrIcao === "Other") {
              arrIcao = ""
            }
            var arr = form.elements.formGridArr.value
            if(!arr || arr.length == 0) {
              arrIcao="";
            }
            console.log("IFR: " + JSON.stringify(form.elements.ifr));
            
            axios.post(
                baseUrl + '/api/ppr/submit',
                { aircraftReg: form.elements.formGridRegistration.value,
                  mtow: form.elements.formGridMtow.value,
                  pic: form.elements.formGridPic.value,
                  phone: form.elements.formGridPhone.value,
                  depDate: form.elements.formGridDepDate.value ? this.formatDate(form.elements.formGridDepDate.value) : this.formatDate(new Date()),
                  dep: form.elements.formGridDep.value,
                  depIcao: depIcao,
                  arrDate: form.elements.formGridArrDate.value ? this.formatDate(form.elements.formGridArrDate.value) : this.formatDate(new Date()),
                  arr: form.elements.formGridArr.value,
                  arrIcao: arrIcao,
                  email: form.elements.formGridEmail.value,
                  depRunway: depRunway,
                  arrRunway: arrRunway,
                  ifr: ifrChecked,
                  },
                { headers: { 'Content-Type': 'application/json' } }
              ).then((response) => {
                  console.log("RESPONSE")
                console.log(JSON.stringify(response.data));
                this.props.navigate('/ui/requeststatus', 
                 {
                  state: {
                    data: response.data,
                    status: response.status,
                    defaultAirport: this.props.defaultAirport
                  }
                }
                )
               
              }, (error) => {
                  console.log(JSON.stringify(error));
                  var messages = error.response.data.errorMessages;
                  if(error.response.status == 500) {
                    
                    this.setState({
                      errorMessages: [{"error":"Internal server error, please try again later"}]
                  }); 
                  }else {
                   
                    this.setState({
                      errorMessages: messages
                  }); 
                  }
                  
                console.log(JSON.stringify(error.response));
              });
              
        }
        
        this.setState({
            validated:true
        })

        let formTarget = event.target
        
      }

 

  render() {
      let validated = this.state.validated;
      let airports = this.state.airports
    
      var adInfoTitle = ""
      var operationModeTitle = ""

     

      if(this.state.adInfo) {


        if(this.state.adInfo.operationMode === "PNR") {
          operationModeTitle="Prior Notice Form (PN)"
        } else if(this.state.adInfo.operationMode.includes("PPR")) {
          operationModeTitle="Prior Permission Required Form (PPR)"
        }

        adInfoTitle = (
          <div>
          <Row className="justify-content-md-center">
            <Col xs lg="10">
            <Alert variant="light">
              <Alert.Heading>{this.state.adInfo.displayName} - {operationModeTitle}</Alert.Heading>
            </Alert>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
          <Col xs lg="10">
          <Alert variant="warning">
              <Alert.Heading>{this.state.adInfo.freeText}</Alert.Heading>
          </Alert>
          </Col>
        </Row>
        </div>
        ) 

        
      }

      if (!airports) {
        return(
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
        )
      }

      var depIcaoFormGroup = "";
      var arrIcaoFormGroup = "";

        depIcaoFormGroup = (<Form.Group controlId="formGridDepIcao">
        <Form.Label>From:  </Form.Label>
        <Form.Control as="select" onChange={this.handleDepIcaoChange.bind(this)} placeholder="Select dep location" defaultValue={this.props.defaultAirport}>
          <option key={0} value={null}></option>
          <option key={1} value={null}>Other</option>
          {
            airports.map((airport, index) => {
              return (<option key={index+2} value={airport.icao}>{airport.icao}</option>)
            })
          })
        </Form.Control>
        </Form.Group>)

        arrIcaoFormGroup = (  <Form.Group controlId="formGridArrIcao">
        <Form.Label>To:  </Form.Label>
        <Form.Control as="select" onChange={this.handleArrIcaoChange.bind(this)} placeholder="Select Arr Location" defaultValue={this.props.defaultAirport}>
        <option key={0} value={null}></option>
        <option key={1} value={null}>Other</option>
          {
            airports.map((airport, index) => {
              return (<option key={index+2} value={airport.icao}>{airport.icao}</option>)
            })
          }
        </Form.Control>
    </Form.Group>)

      

      var errorMessages = []
      let data = this.state.errorMessages;
      console.log("data: " + data);
      for (var key in data) {
        console.log("KEY: " + key + " - " + data[key]);
        if (data.hasOwnProperty(key)) {
           errorMessages.push(data[key].error);
        }
     }

     var errorView = errorMessages.map((error,idx) => (
            <Alert key={idx} variant='danger'>
            {error}
          </Alert>
        ))
    
    var arrRunway = "";
    if( (this.state && this.state.showArrRunway)) {
      arrRunway = (
        <Form.Group controlId="formGridArrRunway">
            <Form.Label>ARR Runway:  </Form.Label>
            <Form.Control as="select" placeholder="Select Runway">
            <option key="23" value="23">23</option>
            <option key="05" value="05">05</option>
            </Form.Control>
        </Form.Group>
    );
    }

    var depRunway = "";
    if(this.state && this.state.showDepRunway) {
      depRunway = (
        <Form.Group controlId="formGridDepRunway">
            <Form.Label>DEP Runway:  </Form.Label>
            <Form.Control as="select" placeholder="Select Runway">
            <option key="05" value="05">05</option>
            <option key="23" value="23">23</option>
            </Form.Control>
        </Form.Group>
  );
  }


  var ifrArrival = "";
  if(this.state && this.state.showIFRCheckbox) {
    ifrArrival = ( <Form.Group controlId="formGridIFR">
    <Form.Label></Form.Label>
    <Form.Check type="checkbox" id="IFR" label="IFR Arrival" />
</Form.Group>);
    }
    
    return (
        <Container>
          {adInfoTitle}
           <Row className="justify-content-md-center">
            <Col xs lg="10">
              {errorView}
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            
          </Row>
          <Row className="justify-content-md-center">
            <Col xs lg="10">
            <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
           
          {depIcaoFormGroup}
          
         
          {arrIcaoFormGroup}
        
       
          <Form.Group controlId="formGridRegistration">
            <Form.Label>Aircraft registration (OHXYZ) (required): </Form.Label>
            <Form.Control required type="text" ref="aircraftReg" placeholder="Aircraft Registration" defaultValue={this.state.defaultAircraft} />
            <Form.Control.Feedback type="invalid">
              Please enter aircraft registration
            </Form.Control.Feedback>
          </Form.Group>
        

       
            <Form.Group controlId="formGridMtow">
                <Form.Label>MTOW (Kg)</Form.Label>
            <Form.Control required placeholder="MTOW in Kg" defaultValue={this.state.defaultMtow} />
            <Form.Control.Feedback type="invalid">
              Please enter MTOW
            </Form.Control.Feedback>
            </Form.Group>
        

       
            <Form.Group controlId="formGridPic">
                <Form.Label>PIC (Full name, required): </Form.Label>
            <Form.Control required placeholder="PIC full name" defaultValue={this.state.defaultPic}/>
            <Form.Control.Feedback type="invalid">
              Please enter PIC name
            </Form.Control.Feedback>
            </Form.Group>
        

       
            <Form.Group controlId="formGridPhone">
                <Form.Label>Phone (required): </Form.Label>
            <Form.Control required placeholder="Phone number" defaultValue={this.state.defaultPhone} />
            <Form.Control.Feedback type="invalid">
              Please enter phone number
            </Form.Control.Feedback>
            </Form.Group>
        

        
        {depRunway}

       
            <Form.Group controlId="formGridDepDate">
                <Form.Label>DEP Date (leave empty if today):  </Form.Label>
            <Form.Control type="date" />
            </Form.Group>
        

       
            <Form.Group controlId="formGridDep">
                <Form.Label>DEP (local HHMM):  </Form.Label>
            <Form.Control placeholder="Enter dep time here" />
            </Form.Group>
        

      
        {arrRunway}

       
            <Form.Group controlId="formGridArrDate">
                <Form.Label>Arr Date (leave empty if today):  </Form.Label>
            <Form.Control type="date"   />
            </Form.Group>
        

       
            <Form.Group controlId="formGridArr">
                <Form.Label>ARR (local HHMM):  </Form.Label>
            <Form.Control placeholder="Enter arr time here" />
            </Form.Group>
        

          {ifrArrival}
           
            <Form.Group controlId="formGridEmail">
                <Form.Label>PIC e-mail (for billing / laskutusta varten):</Form.Label>
            <Form.Control required type="email" placeholder="Email address" defaultValue={this.state.defaultEmail} />
            <Form.Control.Feedback type="invalid">
              Please enter email
            </Form.Control.Feedback>
            </Form.Group>
        
      
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col xs lg="10">
            <Link to="/ui/privacypolicy">
              Privacy policy / Tietosuojaseloste
            </Link>
            </Col>
           
          </Row>


        </Container>
        
    );
  }
  
}

export default PermissionRequest;
